import { useState, Fragment, useEffect } from 'react'
import { useRouter } from 'next/router'
import { useSession, signOut } from 'next-auth/react'

import { styled } from '@mui/material/styles'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import Icon from 'src/@core/components/icon'
import NotificationDropdown from 'src/@core/layouts/components/shared-components/NotificationDropdown'
import ModeToggler from 'src/@core/layouts/components/shared-components/ModeToggler'
import { Box } from '@mui/material'
import WorkerAvatar from 'src/components/shared/WorkerAvatar'

// Create a custom event for color changes
export const avatarColorChangeEvent = new Event('avatarColorChange')

const MenuItemStyled = styled(MenuItem)(({ theme }) => ({
  padding: theme.spacing(2),
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    '& .MuiBox-root, & .MuiBox-root svg, & .MuiTypography-root': {
      color: theme.palette.primary.main
    }
  },
  '& .MuiBox-root': {
    marginRight: theme.spacing(3),
    display: 'flex',
    alignItems: 'center'
  },
  '& svg': {
    fontSize: '1.25rem'
  },
  '&.Mui-focusVisible': {
    backgroundColor: 'transparent'
  }
}))

const UserDropdown = props => {
  const { saveSettings, settings } = props
  const router = useRouter()
  const { data: session } = useSession()
  const [anchorEl, setAnchorEl] = useState(null)
  const [avatarColor, setAvatarColor] = useState(null)

  useEffect(() => {
    // Fetch initial color
    const fetchColor = async () => {
      if (session?.user?.id) {
        try {
          const response = await fetch(`/api/user/getPreferences/${session.user.id}`)
          const data = await response.json()
          setAvatarColor(data.avatar_bg_color)
        } catch (error) {
          console.error('Error fetching avatar color:', error)
        }
      }
    }
    fetchColor()

    // Listen for color changes
    const handleColorChange = async () => {
      if (session?.user?.id) {
        try {
          const response = await fetch(`/api/user/getPreferences/${session.user.id}`)
          const data = await response.json()
          setAvatarColor(data.avatar_bg_color)
        } catch (error) {
          console.error('Error fetching updated avatar color:', error)
        }
      }
    }

    window.addEventListener('avatarColorChange', handleColorChange)
    return () => window.removeEventListener('avatarColorChange', handleColorChange)
  }, [session?.user?.id])

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    signOut({ callbackUrl: '/', redirect: false }).then(() => {
      router.push('/')
    })
    handleClose()
  }

  const handleMyProfileClick = () => {
    router.push('/profile')
    handleClose()
  }

  return (
    <Fragment>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, p: 2 }}>
        <ModeToggler settings={settings} saveSettings={saveSettings} />
        <NotificationDropdown notifications={[]} settings={settings} />
        <Box onClick={handleClick} sx={{ cursor: 'pointer' }}>
          <WorkerAvatar
            showTooltip={false}
            name={session?.user?.name || ''}
            userId={session?.user?.id || ''}
            size={40}
            avatarBgColor={avatarColor}
          />
        </Box>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <MenuItemStyled onClick={handleMyProfileClick}>
            <Box component={Icon} icon='tabler:user' />
            <Typography>My Profile</Typography>
          </MenuItemStyled>
          <MenuItemStyled onClick={handleLogout}>
            <Box component={Icon} icon='tabler:logout' />
            <Typography>Sign Out</Typography>
          </MenuItemStyled>
        </Menu>
      </Box>
    </Fragment>
  )
}

export default UserDropdown
