import React, { useState, useEffect } from 'react'
import { Avatar, CircularProgress, Tooltip, Box } from '@mui/material'
import Image from 'next/image'

const WorkerAvatar = ({
  name,
  userId,
  size = 40,
  hoverAnimation = false,
  showTooltip = true,
  avatarBgColor = null
}) => {
  const [avatarSrc, setAvatarSrc] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [avatarBgColorState, setAvatarBgColorState] = useState(avatarBgColor)
  const [isBirthday, setIsBirthday] = useState(false)
  const [imageError, setImageError] = useState(false)

  useEffect(() => {
    const fetchUserData = async () => {
      if (!userId) {
        setAvatarBgColorState('#7367F0') // Default color if userId is not provided
        return
      }
      try {
        const [preferencesResponse, birthdayResponse] = await Promise.all([
          fetch(`/api/user/getPreferences/${userId}`),
          fetch(`/api/user/getBirthday/${userId}`)
        ])
        const preferencesData = await preferencesResponse.json()
        const birthdayData = await birthdayResponse.json()

        // Only set the background color if no override is provided through props
        if (!avatarBgColor) {
          setAvatarBgColorState(preferencesData.avatar_bg_color || '#7367F0')
        }
        setIsBirthday(birthdayData.isBirthday)
      } catch (error) {
        console.error('Error fetching user data:', error)
        if (!avatarBgColor) {
          setAvatarBgColorState('#7367F0') // Default color on error
        }
      }
    }

    const checkAvatarImage = async () => {
      setIsLoading(true)
      setImageError(false)
      if (name) {
        const fileNames = [`${name.toLowerCase().replace(/\s+/g, '-')}.png`, `${name.toLowerCase()}.png`]

        for (const fileName of fileNames) {
          try {
            const res = await fetch(`/images/avatars/${fileName}`)
            if (res.ok) {
              setAvatarSrc(`/images/avatars/${fileName}`)
              setIsLoading(false)
              return
            }
          } catch (error) {
            console.error(`Error checking avatar image for ${fileName}:`, error)
          }
        }

        console.log('No matching avatar image found for', name)
        setImageError(true)
      }
      setIsLoading(false)
    }

    fetchUserData()
    checkAvatarImage()
  }, [name, userId, avatarBgColor])

  // Update background color when prop changes
  useEffect(() => {
    if (avatarBgColor !== null) {
      setAvatarBgColorState(avatarBgColor)
    }
  }, [avatarBgColor])

  const getInitials = () => {
    if (!name) return '?'
    const nameParts = name.trim().split(/\s+/)
    if (nameParts.length === 1) {
      return nameParts[0].charAt(0).toUpperCase()
    }
    return nameParts
      .map(part => part.charAt(0))
      .join('')
      .toUpperCase()
  }

  const handleImageError = () => {
    setImageError(true)
    setAvatarSrc(null)
  }

  const AvatarContent = (
    <Box sx={{ position: 'relative', display: 'inline-block' }}>
      <Avatar
        sx={{
          width: size,
          height: size,
          fontSize: `${size / 2.5}rem`,
          bgcolor: avatarBgColorState,
          transition: hoverAnimation ? 'transform 0.1s ease-in-out, box-shadow 0.3s ease-in-out' : 'none',
          '&:hover': hoverAnimation
            ? {
                transform: 'translateY(-4px)',
                boxShadow: '0 4px 8px rgba(0,0,0,0.2)'
              }
            : {}
        }}
      >
        {isLoading ? (
          <CircularProgress size={size * 0.6} color='inherit' />
        ) : avatarSrc && !imageError ? (
          <Image
            src={avatarSrc}
            alt={`${name || 'Unknown'} Avatar`}
            width={size}
            height={size}
            onError={handleImageError}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover'
            }}
          />
        ) : (
          getInitials()
        )}
      </Avatar>
      {isBirthday && (
        <Box
          component='img'
          src='/images/party_hat_1.svg'
          alt='Birthday hat'
          sx={{
            position: 'absolute',
            top: -size / 2,
            left: '60%',
            transform: 'translateX(-50%)',
            width: size * 0.8,
            height: size * 0.8,
            zIndex: 2
          }}
        />
      )}
    </Box>
  )

  return showTooltip ? (
    <Tooltip title={name || 'Unknown'} arrow>
      {AvatarContent}
    </Tooltip>
  ) : (
    AvatarContent
  )
}

export default WorkerAvatar
